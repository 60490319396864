<template>
  <b-card>
    <b-row class="p-0 mb-1">
      <b-col cols="4">
        <b-button
          v-if="transactionType === 'returnPurch'"
          :variant="file.name ? 'success' : 'relief-primary'"
          data-action-type="new"
          class="mr-1 btn-sm"
          @click="
            (v) => {
              openModal();
            }
          "
        >
          {{ file.name ? $t('hasAttachMents') : $t('attachFile') }}
          <feather-icon
            icon="UploadIcon"
            size="15"
            class="ml-25"
          />
        </b-button>
        <a
          v-if="transactionType === 'returnPurch' && id && selectedItem.attachmentUrl"
          :href="selectedItem.attachmentUrl"
          class="mr-1 btn-sm btn btn-success"
          @click.prevent="downloadItem(selectedItem)"
        >
          {{ $t('attachedFile') }}
          <feather-icon
            icon="DownloadIcon"
            size="15"
            class="ml-25"
          />
        </a>
      </b-col>
    </b-row>
    <g-form @submit="save">
      <b-row>
        <b-col
          v-if="selectedItem.postedVoucherId"
          cols="9"
          class="text-center"
        >
          <b-button
            :variant="'dark'"
            data-action-type="new"
            class="mr-1 btn-sm"
            @click="
              (v) => {
                this.$router.push({
                  name: 'vouchers-edit',
                  params: { id: selectedItem.postedVoucherId },
                });
              }
            "
          >
            {{ `${$t('voucherNum')} ${selectedItem.postedVoucherId}` }}
          </b-button>
          <hr class="border-bottom-primary border-darken-2">
        </b-col>
      </b-row>
      <b-row class="my-2">
        <b-col
          md="9"
          class="border-end"
        >
          <div class="row">
            <b-col
              v-if="id > 0"
              md="4"
            >
              <g-field
                :value.sync="selectedItem.code"
                label-text="code"
                disabled
              />
            </b-col>
            <b-col
              v-if="currentBranch.enableEditingTransactionDates"
              md="4"
            >
              <g-picker
                :value.sync="selectedItem.transactionDate"
                label-text="date"
                name="date"
              />
            </b-col>
            <b-col
              v-if="!currentBranch.enableEditingTransactionDates"
              md="4"
            >
              <g-field
                :value="getDate(selectedItem.transactionDate)"
                label-text="date"
                disabled
                name="date"
              />
            </b-col>
            <b-col md="4">
              <g-field
                :value="selectedItem.transactionTime"
                label-text="transactionTime"
                name="transactionTime"
                readonly
              />
            </b-col>
            <!-- <b-col md="4">
              <g-field
                label-text="paymentType"
                field="select"
                rules="required"
                :dir="isRight ? 'rtl' : 'ltr'"
                :options="itemTransactionTypes"
                :label="isRight ? 'arabicName' : 'englishName'"
                :value.sync="selectedItem.paymentType"
                disabled
              />
            </b-col> -->
            <b-col md="4">
              <g-field
                :options="lookups.stores"
                label-text="store"
                field="select"
                rules="required"
                name="StoreId"
                :value.sync="selectedItem.storeId"
                :dir="isRight ? 'rtl' : 'ltr'"
                :label="isRight ? 'arabicName' : 'englishName'"
                :disabled="(isClosed || hasSource || selectedItem.lines && selectedItem.lines.length > 0)"
              />
            </b-col>
            <b-col md="4">
              <g-field
                label-text="supplier"
                field="select"
                rules="required"
                :label="isRight ? 'arabicName' : 'englishName'"
                :disabled="isClosed || hasSource"
                :value.sync="selectedItem.dealerId"
                :dir="isRight ? 'rtl' : 'ltr'"
                :options="lookups.suppliers"
                @change="changeCustomer"
              />
            </b-col>
            <!-- <b-col md="4">
              <g-field
                label-text="agent"
                :value.sync="selectedItem.agentId"
                :dir="isRight ? 'rtl' : 'ltr'"
                :options="lookups.agents"
                field="select"
                :label="isRight ? 'arabicName' : 'englishName'"
                :disabled="isClosed || hasSource"
              />
            </b-col> -->
          </div>
        </b-col>
        <b-col
          md="3"
          class="pt-2"
        >
          <h1 class="d-flex justify-content-center">
            {{ $t('total') }}
          </h1>
          <h1 class="d-flex justify-content-center pt-2">
            {{ fraction(this.selectedItem.net) }}
          </h1>
        </b-col>
      </b-row>
      <b-row class="pt-1">
        <!-- notes -->
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label-text="Notes"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <items
            :store-id="selectedItem.storeId"
            :items.sync="selectedItem.lines"
            :has-source="!!selectedItem.sourceTransactionId"
            :transaction-type="transactionType"
            @validate-balance="() => validate(invoiceValidationTypes.balance)"
            @validate-salesPrice="() => validate(invoiceValidationTypes.salesPrice)"
          />
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
      <b-row>
        <b-row>
          <b-col
            cols="12"
            md="8"
            class="pl-2"
          >
            <b-row v-show="isCash">
              <b-col
                cols="12"
                class="pb-2"
              >
                <h6>
                  <strong> {{ $t('paymentWay') }} </strong>
                </h6>
                <b-form-radio-group
                  v-model="payemntType"
                  button-variant="outline-primary"
                  :options="optionsRadio"
                  buttons
                  name="radios-btn-default"
                />
              </b-col>
              <b-col
                v-if="payemntType === 'Safes'"
                md="5"
                class="pr-1"
              >
                <g-field
                  :value.sync="safeTransactions.safeId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  :label="isRight ? 'arabicName' : 'englishName'"
                  :options="lookups.safes"
                  label-text="safe"
                  field="select"
                  @change="(val) => {
                    safeTransactions.safeArabicName = val.arabicName
                    safeTransactions.safeEnglishName = val.englishName
                  }"
                />
              </b-col>
              <b-col
                v-show="payemntType !== 'Safes'"
                md="3"
                class="pr-1"
              >
                <g-field
                  :value.sync="paymentMethod.otherPaymentMethodId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  :label="isRight ? 'arabicName' : 'englishName'"
                  :options="lookups.paymentMethods"
                  label-text="paymentWay"
                  field="select"
                  @change="
                    (val) => {
                      paymentMethod.otherPaymentMethodArabicName = val.arabicName;
                      paymentMethod.otherPaymentMethodEnglishName = val.englishName;
                      bankName = val.bankId
                    }
                  "
                />
              </b-col>
              <b-col
                v-show="payemntType !== 'Safes'"
                md="3"
                class="pr-1"
              >
                <g-field
                  :value.sync="bankName"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  :label="isRight ? 'arabicName' : 'englishName'"
                  :options="banks"
                  label-text="bankName"
                  field="select"
                  disabled
                />
              </b-col>
              <b-col md="4">
                <g-field
                  :value.sync="paymentMethod.value"
                  type="number"
                  :rules="isCash ? `min_value:0|less_or_equal:${remaindered || 0}` : ''"
                  label-text="value"
                  name="value"
                />
              </b-col>
              <b-col
                md="2"
                class="d-flex align-items-center"
              >
                <b-button
                  variant="gradient-primary"
                  class="btn-icon"
                  :disabled="!canAddNewPayment || (payemntType === 'Safes' && !safeTransactions.safeId)"
                  @click="addNewPaymentMethod"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <g-table
                  ref="items-table"
                  :items="selectedItem.payments"
                  :columns="tableColumns"
                  :hide-options="true"
                  :no-action="true"
                  foot-clone
                  per-page="50"
                  :edit-button="{
                    visiable: false,
                    icon: 'PlusSquareIcon',
                    text: 'delete',
                    handler: (item) => {
                      $emit('addNewItem', item);
                    },
                  }"
                  :delete-button="{
                    visiable: true,
                    handler: (item) => {
                      selectedItem.payments = selectedItem.payments.filter((val) => val !== item);
                    },
                  }"
                  @on-create="
                    (v) => {
                      $refs['search-modal'].show();
                    }
                  "
                />
              </b-col>
            </b-row>
          </b-col>
          <!-- preceipt totals -->
          <b-col md="4">
            <!-- notes -->
            <b-col md="12">
              <b-form-group :label="$t('notes')">
                <b-form-textarea
                  id="textarea"
                  v-model="selectedItem.notes"
                  label-text="Notes"
                  rows="3"
                  max-rows="6"
                />
              </b-form-group>
            </b-col>
            <b-card class="card card-congratulations">
              <b-row>
                <b-col
                  md="2"
                  class="d-flex align-items-center"
                >
                  <label
                    for="total"
                    class="text-white font-weight-bolder text-nowrap"
                  >
                    {{ $t('total') }}
                  </label>
                </b-col>
                <b-col
                  cols="12"
                  md="10"
                >
                  <g-field
                    :value="fraction(selectedItem.total)"
                    placeholder="total"
                    type="number"
                    name="total"
                    disabled
                  />
                </b-col>
                <b-col
                  md="2"
                  class="d-flex align-items-center"
                >
                  <label
                    for="total"
                    class="text-white font-weight-bolder text-nowrap"
                  >
                    {{ $t('discont') }}
                  </label>
                </b-col>
                <b-col
                  cols="5"
                  class="pr-0"
                >
                  <g-field
                    :value.sync="selectedItem.discountValue"
                    :disabled="!total || isLineBaseDiscount"
                    size="sm"
                    type="number"
                    :rules="`min_value:0|max_value:${selectedItem.total}`"
                    placeholder="discountValue"
                    name="discountValue"
                    @input="(v) =>{
                      selectedItem.discountValue < 0 || selectedItem.discountValue > selectedItem.total ? v= 0: v;
                      selectedItem.discountValue = v;
                      selectedItem.discountPercentage = 0;
                      computeDiscount({ value: v })}"
                  />
                </b-col>
                <b-col cols="5">
                  <g-field
                    :value.sync="selectedItem.discountPercentage"
                    :disabled="!total || isLineBaseDiscount"
                    size="sm"
                    placeholder="percentage"
                    name="discountPercentage"
                    type="number"
                    rules="min_value:0|max_value:100"
                    @input="(v) => {
                      selectedItem.discountPercentage < 0 || selectedItem.discountPercentage > 100 ? v= 0: v;
                      selectedItem.discountPercentage = v;
                      computeDiscount({ Percentage :v })
                    }"
                  >
                    <template #append>
                      <b-input-group-text> % </b-input-group-text>
                    </template>
                  </g-field>
                </b-col>
                <b-col
                  md="2"
                  class="d-flex align-items-center"
                >
                  <label
                    for="total"
                    class="text-white font-weight-bolder"
                  >
                    {{ $t('netBeforeTax') }}
                  </label>
                </b-col>
                <b-col
                  cols="12"
                  md="10"
                >
                  <g-field
                    :value="fraction(selectedItem.netBeforeTaxes)"
                    placeholder="total"
                    name="total"
                    type="number"
                    disabled
                    readonly
                  />
                </b-col>
                <b-col
                  md="2"
                  class="d-flex align-items-center"
                >
                  <label
                    for="total"
                    class="text-white font-weight-bolder"
                  >
                    {{ $t('tax') }}
                  </label>
                </b-col>
                <b-col
                  cols="12"
                  md="10"
                >
                  <g-field
                    :value="fraction(selectedItem.totalTaxes)"
                    placeholder="total"
                    name="total"
                    type="number"
                    disabled
                    readonly
                  />
                </b-col>
                <b-col
                  md="2"
                  class="d-flex align-items-center"
                >
                  <label
                    for="total"
                    class="text-white font-weight-bolder"
                  >
                    {{ $t('net') }}
                  </label>
                </b-col>
                <b-col
                  cols="12"
                  md="10"
                >
                  <g-field
                    :value="fraction(selectedItem.net)"
                    placeholder="net"
                    type="number"
                    name="Net"
                    readonly
                  />
                </b-col>
              </b-row>
              <hr>
              <b-row
                v-if="id"
                class="m-auto"
              >
                <b-col
                  md="3"
                  class="p-0 d-flex mb-1 align-items-center"
                >
                  <span
                    class="font-weight-bolder"
                  >
                    {{ $t('createdBy') }}
                  </span>
                </b-col>
                <b-col
                  cols="12"
                  md="9"
                >
                  <g-field
                    :value="selectedItem.createdUserName"
                    size="sm"
                    placeholder="createdBy"
                    name="createdBy"
                    readonly
                  />
                </b-col>
                <b-col
                  v-if="selectedItem.updatedUserName"
                  md="3"
                  class="p-0 d-flex mb-1 align-items-center"
                >
                  <span
                    class="font-weight-bolder"
                  >
                    {{ $t('updatedBy') }}
                  </span>
                </b-col>
                <b-col
                  v-if="selectedItem.updatedUserName"
                  cols="12"
                  md="9"
                >
                  <g-field
                    :value="selectedItem.updatedUserName"
                    size="sm"
                    placeholder="updatedBy"
                    name="updatedBy"
                    readonly
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-col
          v-if="!selectedItem.isPosted"
          cols="12"
          class="d-flex justify-content-end sticky-bottom"
        >
          <b-button
            v-if="id || currentBranch.isMaster"
            class="mx-1"
            variant="primary"
            :disabled="selectedItem.lines.length <= 0"
            @click="print(id)"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ $t('print') }}
          </b-button>
          <b-button
            v-permission="$route.meta.permission"
            class="ml-1"
            variant="relief-primary"
            :disabled="selectedItem.lines.length <= 0 || selectedItem.isPosted"
            data-action-type="saveAndPrint"
            @click="save('saveAndPrint')"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ $t('saveAndPrint') }}
          </b-button>
          <b-button
            class="ml-1"
            variant="outline-primary"
            :disabled="selectedItem.lines.length <= 0 || selectedItem.isPosted"
            data-action-type="save"
            @click="togglePendingItem"
          >
            <feather-icon
              :icon="pendingItem.lines && pendingItem.lines.length > 0 ? 'PlayIcon' : 'PauseIcon'"
              class="mr-50"
            />
            {{ pendingItem.lines && pendingItem.lines.length > 0 ? $t('retrive') : $t('saveAsDraft') }}
          </b-button>
          <b-button
            v-permission="$route.meta.permission"
            type="submit"
            :disabled="selectedItem.isPosted"
            class="ml-1"
            data-action-type="save"
            variant="relief-primary"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            {{ $t('save') }}
          </b-button>
          <b-button
            class="ml-1 mr-2"
            variant="outline-primary"
            @click="initObj();"
          >
            <feather-icon
              icon="RotateCwIcon"
              class="mr-50"
            />
            {{ $t('addNew') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
    <b-modal
      ref="upload-modal"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      centered
      size="lg"
      hide-footer
      :title="$t('attachFile')"
    >
      <g-form>
        <b-row>
          <b-col cols="12">
            <div>
              <input
                type="file"
                class="excel-upload-input"
                accept=".pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip"
                @change="handleFileUpload( $event )"
              >
              <b-alert
                v-if="file"
                show
                fade
                class="mt-2 text-center"
                variant="success"
              >
                <div class="alert-body">
                  <span>{{ $t('importSuccsefly') }} {{ file.name }}</span>
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              class="mx-1"
              variant="primary"
              @click="closeModal"
            >
              {{ $t('save') }}
            </b-button>
            <b-button
              class="mx-1"
              variant="danger"
              data-action-type="delete"
              :disabled="!file"
              @click="() => {
                removeAttachment();
              }"
            >
              {{ $t('cancel') }}
            </b-button>
          </b-col>
        </b-row>
      </g-form>
    </b-modal>
  </b-card>
</template>

<script>
import saveAs from 'file-saver';
import invoiceMixin from '@/mixin/purchaseMixin';
import reportMixin from '@/mixin/reportMixin';
import GTable from '@/pages/Shared/Table.vue';
import items from './components/items.vue';

export default {
  components: {
    items,
    GTable,
  },
  mixins: [
    invoiceMixin, reportMixin
  ],
  props: ['id'],
  data() {
    return {
      file: '',
      disableEdit: false,
      transactionType: 'returnPurch',
      payemntType: 'Safes',
      paymentMethod: {
        value: null,
        id: null,
        arabicName: '',
        englishName: '',
      },
      salesTaxLinks: [],
      safeTransactions: {},
      customer: {},
      payment: {
        transactionId: 0,
        lineSerial: 0,
        value: 0,
        paymentMethod: 'safe',
        safeId: 0,
        otherPaymentMethodId: 0,
        referenceNumber: 'string',
      },
      items: [],
      errors: [],
      pendingItem: {},
      banks: [],
      bankName: null,
      isCalculatingDiscount: false,
      suppliers: []
    };
  },
  computed: {
    canAddNewPayment() {
        return (this.remaindered && Number(this.paymentMethod.value) > 0 && Number(this.paymentMethod.value) <= this.remaindered)
          && ((this.payemntType === 'Safes' && this.safeTransactions) || this.paymentMethod.otherPaymentMethodId);
    },
    isCash() {
      return this.selectedItem.paymentType === 'cash';
    },
    total() {
      return this.selectedItem.lines.reduce((sum, item) => {
        sum += item.net;
        return sum;
      }, 0);
    },
    paid() {
      return this.selectedItem.payments.reduce((sum, item) => {
        sum += parseFloat(item.value);
        return sum;
      }, 0);
    },
    remaindered() {
      return this.fraction(this.selectedItem.net - this.paid) || 0;
    },
    optionsRadio() {
      return [
        { text: this.$t('safe'), value: 'Safes' },
        { text: this.$t('other'), value: 'PaymentMethods' },
      ];
    },
  },
  watch: {
    'selectedItem.paymentType'(newVal, oldVal) {
      if (this.disableEdit === false && newVal !== 'cash' && this.selectedItem.payments.length > 0) {
        this.selectedItem.paymentType = oldVal;
        this.confirmAction({
          text: this.$t('deletePaymentConfirmation'),
        }, () => {
          this.selectedItem.payments = [];
          this.selectedItem.paymentType = newVal;
        });
      }
    },
    'selectedItem.lines'(newVal, oldVal) {
      if (oldVal && oldVal.length > 0 && !this.id && !this.sourceTransactionId) this.selectedItem.payments = [];
    },
    remaindered(newVal) {
      this.paymentMethod.value = newVal;
    },
    total(newval) {
      this.selectedItem.total = newval;
      this.computeTotals();
      if (this.selectedItem.payments.length && !this.id && !this.sourceTransactionId) {
        this.selectedItem.payments = [];
      }
    },
    'selectedItem.total'(newVal, oldVal) {
      if (newVal !== oldVal) {
      this.computeDiscount({ value: this.selectedItem.discountValue });
      }
    },
    'selectedItem.totalTaxes'(newVal) {
      if (newVal) {
        const obj = this.suppliers.find(x => x.id === this.selectedItem.dealerId);
      if (obj && !obj.isTaxable) {
        this.selectedItem.totalTaxes = 0;
        this.selectedItem.netBeforeTaxes = this.selectedItem.total - this.selectedItem.discountValue;
        this.selectedItem.net = this.selectedItem.netBeforeTaxes;
      }
      }
    },
    'selectedItem.dealerId'(newVal) {
      if (newVal) {
      const obj = this.suppliers.find(x => x.id === this.selectedItem.dealerId);
      if (obj && !obj.isTaxable) {
        this.selectedItem.totalTaxes = 0;
        this.selectedItem.netBeforeTaxes = this.selectedItem.total - this.selectedItem.discountValue;
        this.selectedItem.net = this.selectedItem.netBeforeTaxes;
      }
    }
  }
  },
  mounted() {
    this.getBanks();
    this.getSuppliers();
    this.sourceId = this.selectedItem.sourceTransactionId;
    this.updateViewModel('purch');
    const id = this.id || this.selectedItem.sourceTransactionId;
    if (id > 0) {
      let transactionType = this.$route.query.sourceTransactionType;
      if (this.id) transactionType = this.transactionType;
      if (this.selectedItem.sourceTransactionId) {
        this.disableEdit = true;
      }
      this.get({ url: `ItemTransactions/${transactionType}/${id}` }).then(
        (data) => {
          this.selectedItem = data;
          this.selectedItem.notes = data.code;
          this.selectedItem.totalTaxes = data.totalTaxes;
          this.selectedItem.net = data.net;
          this.selectedItem.netBeforeTaxes = data.netBeforeTaxes;
          this.selectedItem.lines.forEach((item) => {
            item.originalQuantity = item.quantity;
            item.consumedQuantity = item.consumedQuantity === null ? 0 : item.consumedQuantity;
            item.units = [
              {
                unitId: item.unitId,
                unitArabicName: item.unitArabicName,
                unitEnglishName: item.unitEnglishName,
                price: item.salesPrice,
                minSalesPrice: item.minSalesPrice,
                maxSalesPrice: item.maxSalesPrice,
                barcode: item.barcode,
                isMain: true,
              },
            ];
            item.barCode = item.barcode
          });
          if (this.sourceId > 0) this.prepareItemfromSource();
        }
      );
    }
  },
  methods: {
    openModal() {
      this.$refs['upload-modal'].show();
    },
    closeModal() {
      this.$refs['upload-modal'].hide();
    },
    removeAttachment() {
      this.selectedItem.attachmentFileExtension = '';
      this.selectedItem.attachmentBase64Content = '';
      this.selectedItem.attachmentFileName = '';
      this.file = '';
      this.closeModal();
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      const fileSize = this.file.size / 1024 / 1024;
      this.selectedItem.attachmentFileExtension = this.file.name.split('.').pop();
        this.toBase64(this.file).then((file1) => {
          this.selectedItem.attachmentBase64Content = file1.split(',').pop();
        });
        this.selectedItem.attachmentFileName = URL.createObjectURL(this.file);
      if (!this.isValidType(this.file)) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({ text: this.$t('Only supports upload .pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip suffix files'), type: 'error' });
        this.file = '';
        return false
      }
      if (this.isValidType(this.file) && fileSize > 5) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({ text: this.$t('fileSizeExceedsFiveMiB'), type: 'error' });
        this.file = '';
        return false
      }
    },
    isValidType(file) {
      return /\.(pdf|jpg|jpeg|bmp|png|doc|docx|zip)$/.test(file.name)
    },
    downloadItem(item) {
    const path = item.attachmentUrl.split('.')[1];
    saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
    },
    getSuppliers() {
      this.get({ url: 'Suppliers/summary?dealerType=payments' }).then((data) => {
          this.suppliers = data;
        });
    },
    getBanks() {
      this.get({ url: 'Banks' }).then((data) => {
        this.banks = data;
      });
    },
    initObj() {
      this.getSelecteItemObj();
      this.selectedItem.transactionType = this.transactionType;
    },
    prepareItemfromSource() {
      this.selectedItem.id = 0;
      this.selectedItem.expenses = [];
      this.selectedItem.transactionType = this.transactionType;
      this.selectedItem.referenceNumber = null;
      if (this.sourceId) {
        this.selectedItem.isPosted = false;
        this.selectedItem.postedVoucherId = null;
        this.selectedItem.voucherCode = null;
        this.selectedItem.costCenters = [];
      }
      this.selectedItem.uuid = '00000000-0000-0000-0000-000000000000';
      this.selectedItem.lines = this.selectedItem.lines.map((item) => {
         return {
          ...item,
          consumedQuantity: item.consumedQuantity === null ? 0 : item.consumedQuantity,
          originalQuantity: item.quantity,
          quantity: item.quantity - (item.consumedQuantity || 0),
          total: item.price * (item.quantity - (item.consumedQuantity || 0)),
          net: item.total,
        };
      });
      this.selectedItem.transactionTime = this.time;
      this.selectedItem.transactionDate = this.today;
      this.selectedItem.code = null;
      this.selectedItem.sourceTransactionId = this.sourceId;
    },
    togglePendingItem() {
      if (this.selectedItem.lines && this.selectedItem.lines.length > 0) {
        this.pendingItem = JSON.parse(JSON.stringify(this.selectedItem));
        this.getSelecteItemObj();
      } else if (this.pendingItem.lines && this.pendingItem.lines.length > 0) {
        this.selectedItem = this.pendingItem;
        this.pendingItem = {};
      } else {
        this.doneAlert({ text: this.$t('selectItems'), type: 'warning' });
      }
    },
    changeCustomer(v) {
      if (!this.id) {
        this.selectedItem.agentId = v.agentId || null;
        this.selectedItem.onTheFlyDealerName = '';
      }
      if (!this.isLineBaseDiscount) {
        this.selectedItem.discountPercentage = v.applyDiscount && !this.isLineBaseDiscount ? v.discount : 0;
      }
      this.computeDiscount({});
      this.customer = v;
    },
    computeTotals() {
      // get discounted
      this.computeTotalDiscount();

      this.selectedItem.netBeforeTaxes = parseFloat(this.selectedItem.total) - parseFloat(this.selectedItem.discountValue) || 0;
      this.selectedItem.totalTaxes = 0;
      this.selectedItem.net = this.selectedItem.netBeforeTaxes;
      // compute tax values in mixin
      this.computeTotalTaxes();

      this.selectedItem.net = this.fraction(this.selectedItem.net);
      this.selectedItem.netBeforeTaxes = this.fraction(this.selectedItem.netBeforeTaxes);
      this.selectedItem.totalTaxes = Number(this.fraction(this.selectedItem.totalTaxes));
      if (this.currentBranch.taxPolicy === 'priceIncluded') {
        this.selectedItem.netBeforeTaxes = this.selectedItem.net / (1 + (this.company.taxPercentage / 100));
        this.selectedItem.totalTaxes = Number(this.selectedItem.net) - Number(this.selectedItem.netBeforeTaxes);
      }
    },
    computeDiscount({ value, Percentage }) {
      if (!this.total) return;
      if (this.isCalculatingDiscount) return;
        this.isCalculatingDiscount = true;
      value = value || 0;
      Percentage = Percentage || 0;
     if (value) {
        this.selectedItem.discountPercentage = this.fraction((value / parseFloat(this.total)) * 100);
      } else {
        this.selectedItem.discountValue = this.fraction((parseFloat(this.total) * Percentage) / 100);
      }
      // compute totals after apply discount
      this.computeTotals();
      this.isCalculatingDiscount = false;
    },
    addNewPaymentMethod() {
      if (this.paymentMethod.value && this.paymentMethod.value <= this.remaindered) {
        this.preparePaymentModel();

        if (this.selectedItem.payments.some((p) => (p.safeId && p.safeId === this.paymentMethod.safeId) || (p.otherPaymentMethodId && p.otherPaymentMethodId === this.paymentMethod.otherPaymentMethodId))) {
          this.doneAlert({ text: this.$t('existingPayment'), type: 'error' });
        } else {
          this.selectedItem.payments.push({ ...this.paymentMethod });
        }

        this.paymentMethod = {};
      }
    },
     getAppliedTaxesOnReturnPurch() {
      // compute applied taxes
     // if (this.id > 0) return;
      if (this.currentBranch.taxPolicy === 'netBased') {
        this.selectedItem.taxes = this.salesTaxLinks.map((tax) => {
          return {
            arabicName: tax.taxType.arabicName,
            englishName: tax.taxType.englishName,
            taxTypeId: tax.taxType.id,
            taxPercentage: tax.taxType.taxPercentage,
            taxValue: 0,
          };
        });
      } else this.selectedItem.taxes = [];
    },
    save(type) {
      if (!this.beforeSaveValidation()) return;
      if (!this.beforeSaveValidationDealer()) return;
      if (this.errors.length > 0) {
        this.doneAlert(this.errors[0]);
        return;
      }
      if (this.selectedItem.payments.length <= 0) {
        this.selectedItem.paymentType = 'credit';
      }
      // if paid amount more than needed
      if (this.remaindered < 0) {
        this.doneAlert({
          type: 'error',
          text: this.$t('paidMoreThanRequired'),
        });
        return;
      }
      const url = `ItemTransactions/${this.transactionType}`;
      (this.id > 0 ? this.update({ url, id: this.id, data: this.getModel() }).then(() => {
        if (type === 'saveAndPrint') this.print(this.id);
      })
        : this.create({ url, data: this.getModel() }))
        .then((data) => {
          // if edit or from another source go to new after save
          if (this.id > 0 || this.selectedItem.sourceTransactionId > 0) this.$router.push({ name: 'purchInvoice-list' });
          this.doneAlert({
            title: this.$t('savedSuccessfully'),
            type: 'success',
          });
          if (type === 'saveAndPrint') {
            this.print(data.id);
            this.getSelecteItemObj();
          }
          this.getSelecteItemObj();
        });
    },
     print(id) {
      const printedItem = {
        id: id,
        branchId: this.currentBranch.id
      }
       this.printReport('ReturnPurch-ar', printedItem);
    },
  },
};
</script>
